import { Size } from '@front/model'
import type { AvatarProps as MantineAvatarProps } from '@mantine/core'
import { Avatar, rem, Skeleton } from '@mantine/core'
import type { ReactElement } from 'react'
import { BlurhashCanvas } from 'react-blurhash'

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | number | `${number}px`
export type WithAvatarSize = { size?: AvatarSize }

export type AvatarProps = {
  avatarBlurhash?: string
  avatarUrl?: string
  initials?: string
  size?: AvatarSize
}

type Props = Omit<MantineAvatarProps, 'src' | 'size'> & AvatarProps

const sizes: Record<AvatarSize, string> = {
  xs: rem('24px'),
  sm: rem('32px'),
  md: rem('44px'),
  lg: rem('96px'),
}

export const AvatarBlurhash = ({ avatarBlurhash, avatarUrl, initials, ...rest }: Props): ReactElement => {
  const { size } = rest
  return (
    <div id='avatar' className='relative'>
      {avatarBlurhash && (
        <div className='absolute'>
          <BlurhashCanvas
            hash={avatarBlurhash}
            style={{
              borderRadius: Size.radius.full,
              width: getSize(size),
              height: getSize(size),
            }}
          />
        </div>
      )}
      <Avatar
        src={avatarBlurhash ? `${avatarUrl}?bh=${encodeURIComponent(avatarBlurhash)}` : undefined}
        variant='filled'
        {...rest}
        size={getSize(size)}
      >
        {!avatarBlurhash && initials}
      </Avatar>
    </div>
  )
}

export const getSize = (size: AvatarSize = 'sm'): string | number => {
  if (typeof size === 'number') return rem(`${size}px`)
  if (size in sizes) return sizes[size]

  return size
}

export const AvatarSkeleton = ({ size }: WithAvatarSize) => {
  return <Skeleton circle h={getSize(size)} w={getSize(size)} />
}
